/* eslint-disable no-nested-ternary */
import { useEffect, useContext, useState } from 'react';
import type { FC } from 'react';
import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Grid,
  Link,
  Typography,
  Button,
  List,
  ListSubheader,
  IconButton,
  TextField
} from '@material-ui/core';
import { MonetizationOn } from '@material-ui/icons';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import AssistantIcon from '@material-ui/icons/Assistant';
import SpeedIcon from '@material-ui/icons/Speed';
import FilterGerencia from 'src/components/dashboard/gerencia/FilterGerencia';
import AgenciesFilter from 'src/components/agencias/AgenciesFilter';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import ArchiveIcon from '@material-ui/icons/Archive';
import TheatersIcon from '@material-ui/icons/Theaters';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ExposureIcon from '@material-ui/icons/Exposure';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import dayjs from 'dayjs'
import BallotIcon from '@material-ui/icons/Ballot';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import WebIcon from '@material-ui/icons/Web';
import { GiSherlockHolmes, GiReceiveMoney, GiTicket } from 'react-icons/gi'
import { FaMapMarkedAlt } from 'react-icons/fa'
import ArrowRight from '../../icons/ArrowRight';
import SearchIcon from '../../icons/Search';
import ArrowLeft from '../../icons/ArrowLeft';
import ShoppingBagIcon from '../../icons/ShoppingBag';
import BriefcaseIcon from '../../icons/Briefcase';
import { UMNContext, RoleContext, AgenciaContext, FilterGerenciaContext } from '../../contexts'

import useAuth from '../../hooks/useAuth';

import UserIcon from '../../icons/User';
import UsersIcon from '../../icons/Users';
import CollectionIcon from '../../icons/Collection';
import HomeIcon from '../../icons/Home'
import Phone from '../../icons/Phone';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import Label from '../Label'

import { useGetUmnsQuery, useGetAgencyByUmnQuery, useZonaAgenciaQuery, useAllAgenciasQuery } from '../../generated/graphql'

import { DateAndTimePickers } from '../Date/DateFilter'
import { DateConsumer } from '../../contexts/DateContext'


interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const [zona, setZona] = useState([0]);
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const { UMN, setUMN } = useContext(UMNContext);
  const { agencia, dataAgencia, searchAgencie } = useContext(AgenciaContext);
  const { handleSearchGerencia } = useContext(FilterGerenciaContext)
  const { role } = useContext(RoleContext)
  const umnId = UMN

  let searchDate;

  const handleFilter = () => {
    handleSearchGerencia()
    searchDate()
    searchAgencie()
  }


  useEffect(() => {
    // render select agencia with context
  }, [dataAgencia])

  const { data: dataUmn } = useGetUmnsQuery({ variables: { agencia, zona }, fetchPolicy: 'network-only' })
  const { data: agenciaNumber } = useGetAgencyByUmnQuery({ variables: { id: umnId }, fetchPolicy: 'network-only' })

  const { data: { getZonasByAgencia } = {} } = useZonaAgenciaQuery({ variables: { agencia: agenciaNumber?.umn?.agencia_numero } })
  const { data: { allAgencies } = {} } = useAllAgenciasQuery();

  useEffect(() => { }, [allAgencies])
  let umnS = role?.includes('tdp_operativa') || role?.includes('cumplimiento') || role?.includes('finanzas') || role?.includes('tesoreria') || role?.includes('conajzar') || role?.includes('conajzar_raspa') || role?.includes('raspa_operativa') ? dataUmn?.allUmns : user.umns

  let sections;
  console.log('usuarios_with_roles',user)
  // console.log(user?.permisos.map((permiso) => permiso.Role.name))
  if (agencia[0] === 1) {
    console.log("agencia posicion 0", agencia[0])
    console.log("agencia posicion 1", agencia[1])
    // console.log("agencia dos posiciones", agencia)
    sections = role?.includes('tdp_gerencia', 'presidencia') ?
      [
        {
          title: 'Gerencia',
          items: [
            {
              title: 'General',
              path: '/gerencia',
              icon: <HomeIcon fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia']
            },
            {
              title: 'Diferencia',
              path: '/gerencia/diferencias',
              icon: <ExposureIcon fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia']
            },
            {
              title: 'Cubrida',
              path: '/gerencia/cubridas',
              icon: <BeenhereIcon fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia', 'tdp_operativa']
            },
             {
              title: 'Carga de Sorteos especiales',
              path: '/gerencia/especiales',
              icon: <AssistantIcon fontSize="small" />,
              role: ['dw']
            }
          ]
        },
        {
          title: 'Reportes',
          items: [

            {
              title: 'Reportes',
              path: '/conajzar/reporteFinanciero',
              icon: <UsersIcon fontSize="small" />,
              role: ['presidencia', 'tdp_gerencia', 'finanzas', 'admin_juego', 'tdp_operativa'],
              children: [
                {
                  title: 'Analisis de Frecuencia',
                  path: '/gerencia/frecuencia'
                },
                {
                  title: 'Analisis de Jugadas',
                  path: '/gerencia/analisis'
                },
                {
                  title: 'Análisis de ocurrencia',
                  path: '/ocurrenciaGraficos'
                },
                {
                  title: 'Bolillero',
                  path: '/bolillero'
                },
                {
                  title: 'Cuentas',
                  path: '/estadoCuenta'
                },
                {
                  title: 'Estado de Cuenta Agencias',
                  path: '/gerencia/estadoCuentas',
                },
                {
                  title: 'Historial de Sorteos',
                  path: '/historialDeSorteos',
                },
                {
                    title: 'Investigar Usuario',
                    path: '/gerencia/investigar/usuario'
                },
                {
                  title: 'Montos por Boletas',
                  path: '/boletasPorMonto',
                },
                {
                  title: 'Ocurrencias',
                  path: '/ocurrenciasGlobales'
                },
                {
                  title: 'Ocurrencias de Tómbola',
                  path: '/ocurrenciasTombola'
                },
                {
                  title: 'Quiniela Web',
                  path: '/qweb/reporte'
                },
                {
                  title: 'Stock General Raspadita',
                  path: '/stock/raspadita-tipo-juego',
                },
                {
                  title: 'Saldo en Bolsa Tigo',
                  path: '/bolsaTigo'
                },
              ]
            },
          ]
        },
        {
          title: 'Quiniela Web',
          items: [
            {
              title: 'Quiniela Web',
              path: '/web',
              icon: <BallotIcon fontSize="small" />,
              role: ['presidencia', 'tdp_gerencia'],
              children: [
                {
                  title: 'Recaudaciones',
                  path: '/web/recaudaciones',
                },
                {
                  title: 'General',
                  path: '/web/general',
                },
                {
                  title: 'Estadistica QWeb',
                  path: '/estadisticaQweb',
                  icon: <EqualizerIcon fontSize="small" />,
                  role: ['tdp_gerencia']
                },
              ]
            }
          ]
        },
        {
          title: 'Control de POS',
          items: [
            {
              title: 'Control de POS',
              path: '/control-pos',
              icon: <HomeIcon fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia', 'umn', 'tdp_operativa', 'finanzas', 'tesoreria', 'conajzar', 'conajzar_raspa', 'raspa_operativa', 'corredor', 'cobrador', 'raspa_gerencia', 'agencia']
            },
            {
              title: 'Geolocalización',
              path: '/geo',
              icon: <FaMapMarkedAlt fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia', 'umn', 'tdp_operativa', 'finanzas', 'tesoreria', 'conajzar', 'conajzar_raspa', 'raspa_operativa', 'corredor', 'cobrador', 'raspa_gerencia', 'agencia']
            }
          ]
        },
        {
          title: 'Raspadita',
          items: [
            {
              title: 'Asignaciones',
              path: '/raspaditaAsignaciones',
              icon: <GiTicket fontSize="small" />,
              role: ['presidencia']
            },
            {
              title: 'Stock',
              icon: <GiTicket fontSize="small" />,
              role: ['presidencia'],
              children: [
                {
                  title: 'Stock General',
                  path: '/stock/raspadita-tipo-juego',
                },
                {
                  title: 'Stock Días Asignados',
                  path: '/stock/raspadita-tipo-juego-dias-asignados',
                },
                {
                  title: 'Inventario',
                  path: '/raspaditas/inventario',
                }
              ]
            },
            {
              title: 'Reportes Financiero',
              path: '/raspadita',
              icon: <BallotIcon fontSize="small" />,
              role: ['raspa_operativa'],
              children: [
                {
                  title: 'Ganadores',
                  path: '/ganadores',
                },
                {
                  title: 'Facturación',
                  path: '/facturacion-raspadita',
                },
                {
                  title: 'Premios',
                  path: '/raspaditas/premios'
                },
                {
                  title: 'Movimiento Financiero',
                  path: '/raspaditaMovimientoFinanciero',
                  role: ['presidencia']
                }
              ]
            },
            {
              title: 'Ventas por Corredor',
              path: '/ventas-por-corredor',
              icon: <GiTicket fontSize="small" />,
              role: ['presidencia', 'gerencia']
            },
            {
              title: 'Asignadas por Corredor',
              path: '/asignadas-por-corredor',
              icon: <GiTicket fontSize="small" />,
              role: ['presidencia', 'gerencia']
            }
          ]
        },
        {
          title: 'Gerencia',
          items: [
            {
              title: 'Sherlock',
              path: '/mode-holmes',
              icon: <GiSherlockHolmes fontSize="small" />,
              role: ['presidencia']
            }
          ]
        },
      ]
      :
      sections = role?.includes('raspa_operativa') ?
        [
          {
            title: 'Reporte Raspadita',
            items: [
              {
                title: 'Reportes Raspadita',
                path: '/raspadita',
                icon: <BallotIcon fontSize="small" />,
                role: ['raspa_operativa'],
                children: [
                  {
                    title: 'Ganadores',
                    path: '/ganadores',
                  },
                  {
                    title: 'Facturación',
                    path: '/facturacion-raspadita',
                  },
                  {
                    title: 'Premios',
                    path: '/raspaditas/premios'
                  },
                ]
              }
            ]
          }
        ]
        :
        role?.includes('conajzar') ?
          [
            {
              title: 'Conajzar',
              items: [
                // {
                //   title: 'Informe de cuenta del jugador',
                //   path: '/estadoJugador',
                //   icon: <AssignmentIndIcon fontSize="small" />,
                //   role: ['conajzar']
                // },
                {
                  title: 'Reporte Financiero',
                  path: '/reporteFinanciero',
                  icon: <UsersIcon fontSize="small" />,
                  role: ['conajzar'],
                  children: [
                    {
                      title: 'Venta por agencias',
                      path: '/ventaAgencias',
                    },
                    {
                      title: 'Medios electrónicos',
                      path: '/mediosElectronicos',
                    },
                    {
                      title: 'Terminales',
                      path: '/mediosTerminales'
                    },
                  ]
                },
                {
                  title: 'Reportes del sistema',
                  path: '/reporteSistema',
                  icon: <UserIcon fontSize="small" />,
                  role: ['conajzar']
                },
                {
                  title: 'Reporte de facturación Agencia',
                  path: '/reporteAgencia',
                  icon: <ConfirmationNumberIcon fontSize='small' />,
                  role: ['conajzar']
                },
                {
                  title: 'Reporte de facturación Diaria',
                  path: '/reporteFacturacionDiaria',
                  icon: <ConfirmationNumberIcon fontSize='small' />,
                  role: ['conajzar']
                },
                {
                  title: 'Reporte de eventos significativos',
                  path: '/reporteDeEventos',
                  icon: <UsersIcon fontSize="small" />,
                  role: ['conajzar'],
                  children: [
                    {
                      title: 'Intentos de login fallidos',
                      path: '/loginFallidos',
                    },
                    {
                      title: 'Uptime del sistema',
                      path: '/uptimeSistema',
                    },
                    {
                      title: 'Anulaciones',
                      path: '/anulacionesEventos'
                    },
                    {
                      title: 'Update de software',
                      path: '/softwareUpdate'
                    },
                    {
                      title: 'Logs de parametros del sistema',
                      path: '/parametrosSistema'
                    },
                    {
                      title: 'Hearbeats',
                      path: '/hearbeatsEventos'
                    },
                    {
                      title: 'Registro de asistencias',
                      path: '/registroAsistencia'
                    },
                    {
                      title: 'Auditoria de Firewall',
                      path: '/auditoriaFirewall'
                    },
                    {
                      title: 'Reporte de acceso remoto',
                      path: '/accesoRemoto'
                    },
                    {
                      title: 'Apuestas Abortadas',
                      path: '/apuestasAbortadas'
                    },
                    {
                      title: 'Cambios de posibilidades Comision/Porcentaje',
                      path: '/cambiosPosibilidades'
                    },
                    {
                      title: 'Errores de incompatibilidad',
                      path: '/erroresIncompatibildad'
                    },
                  ]
                },
                {
                  title: 'Reporte de Pago del premio',
                  path: '/reportePagoPremios',
                  icon: <ConfirmationNumberIcon fontSize='small' />,
                  role: ['conajzar']
                },
                {
                  title: 'Reporte de premios superiores',
                  path: '/reportesPagoSuperior',
                  icon: <ConfirmationNumberIcon fontSize='small' />,
                  role: ['conajzar']
                },
                {
                  title: 'Reporte de sorteos',
                  path: '/informeLiquidaciones',
                  icon: <BallotIcon fontSize='small' />,
                  role: ['conajzar']
                },
                // {
                //   title: 'Reporte de Raspaditas',
                //   path: '/reporteDeRaspadita',
                //   icon: <BallotIcon fontSize='small' />,
                //   role: ['conajzar'],
                //   children: [
                //     {
                //       title: 'Facturación',
                //       path: '/facturacion-raspadita',
                //     },
                //     {
                //       title: 'Ganadores',
                //       path: '/ganadores',
                //     }
                //   ]
                // },
              ]
            },
          ] :
          role?.includes('conajzar_raspa') ?
            [
              {
                title: 'Raspadita',
                items: [
                  {
                    title: 'Reporte de Raspaditas',
                    path: '/reporteDeRaspadita',
                    icon: <BallotIcon fontSize='small' />,
                    role: ['conajzar_raspa'],
                    children: [
                      {
                        title: 'Facturación',
                        path: '/facturacion-raspadita',
                      },
                      {
                        title: 'Ganadores',
                        path: '/ganadores',
                      }
                    ]
                  },
                ]
              },
            ]
            :
            role?.includes('cobrador', 'bridget') ?
              [
                {
                  title: 'Cobrador',
                  items: [
                    {
                      title: 'Entregas',
                      path: '/entregas',
                      icon: <ShoppingBagIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    },
                    user?.name === "JORGELINA PAOLA GAMARRA PAREDES" ?
                      {
                        title: 'Pago Premios',
                        path: `/pagoDePremios`,
                        icon: <GiReceiveMoney fontSize="small" />,
                        role: ['cobrador']
                      } : null,
                    {
                      title: 'Estados Corredor',
                      path: '/estadosCorredor',
                      icon: <AssignmentIndIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'umn', 'agencia']
                    },
                    {
                      title: 'Cuenta',
                      path: '/profile',
                      icon: <UserIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    },
                    {
                      title: 'Tickets',
                      path: '/tickets',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    },
                    {
                      title: 'Billetaje',
                      path: '/billetaje',
                      icon: <DirectionsBusIcon fontSize='small' />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    }
                  ]
                },
                {
                  title: 'Western Union',
                  items: [
                    {
                      title: 'Western Union',
                      path: '/westerUnion/verificar-mtcn',
                      icon: <MonetizationOn fontSize="small" />,
                      role: ['bridget'],
                    },
                  ]
                },
              ] :
              [
                {
                  title: 'Gerencia',
                  items: [
                    {
                      title: 'General',
                      path: '/gerencia',
                      icon: <HomeIcon fontSize="small" />,
                      role: ['tdp_gerencia', 'presidencia']
                    },
                    {
                      title: 'Diferencia',
                      path: '/gerencia/diferencias',
                      icon: <ExposureIcon fontSize="small" />,
                      role: ['tdp_gerencia', 'presidencia']
                    },
                    {
                      title: 'Cubrida',
                      path: '/gerencia/cubridas',
                      icon: <BeenhereIcon fontSize="small" />,
                      role: ['presidencia', 'tdp_gerencia', 'tdp_operativa']
                    }
                  ]
                },
                {
                  title: "Facturación",
                  items: [
                    {
                      title: 'Facturación Telefonía',
                      path: '/facturacion-telefonia',
                      icon: <HomeIcon fontSize="small" />,
                      role: ['tdp_gerencia', 'presidencia', 'admin_juego']
                    },
                  ]
                },
                {
                  title: 'Control de POS',
                  items: [
                    {
                      title: 'Control de POS',
                      path: '/control-pos',
                      icon: <HomeIcon fontSize="small" />,
                      role: ['tdp_gerencia', 'presidencia', 'umn', 'tdp_operativa', 'finanzas', 'tesoreria', 'conajzar', 'conajzar_raspa', 'raspa_operativa', 'corredor', 'cobrador', 'raspa_gerencia', 'agencia']
                    },
                    {
                      title: 'Geolocalización',
                      path: '/geo',
                      icon: <FaMapMarkedAlt fontSize="small" />,
                      role: ['tdp_gerencia', 'presidencia', 'umn', 'tdp_operativa', 'finanzas', 'tesoreria', 'conajzar', 'conajzar_raspa', 'raspa_operativa', 'corredor', 'cobrador', 'raspa_gerencia', 'agencia']
                    }
                  ]
                },
                {
                  title: 'Quiniela Web',
                  items: [
                    {
                      title: 'Promociones',
                      path: '/web/operativa/promociones',
                      icon: <WebIcon fontSize="small" />,
                      role: ['qweb_operativa']
                    }
                  ]
                },
                {
                  title: 'Reportes',
                  items: [
                    {
                      title: 'Reportes',
                      path: '/conajzar/reporteFinanciero',
                      icon: <UsersIcon fontSize="small" />,
                      role: ['tdp_gerencia', 'presidencia'],
                      children: [
                        {
                          title: 'Analisis de Frecuencia',
                          path: '/gerencia/frecuencia'
                        },
                        {
                          title: 'Analisis de Jugadas',
                          path: '/gerencia/analisis'
                        },
                        {
                          title: 'Análisis de ocurrencia',
                          path: '/ocurrenciaGraficos'
                        },
                        {
                          title: 'Bolillero',
                          path: '/bolillero'
                        },
                        {
                          title: 'Cuentas',
                          path: '/estadoCuenta'
                        },
                        {
                          title: 'Estado de Cuenta Agencias',
                          path: '/gerencia/estadoCuentas',
                        },
                        {
                          title: 'Historial de Sorteos',
                          path: '/historialDeSorteos',
                        },
                        {
                          title: 'Investigar Usuario',
                          path: '/gerencia/investigar/usuario'
                        },
                        {
                          title: 'Montos por Boletas',
                          path: '/boletasPorMonto',
                        },
                        {
                          title: 'Ocurrencias',
                          path: '/ocurrenciasGlobales'
                        },
                        {
                          title: 'Ocurrencias de Tómbola',
                          path: '/ocurrenciasTombola'
                        },
                        {
                          title: 'Quiniela Web',
                          path: '/gerencia/qweb/reporte'
                        },
                        {
                          title: 'Saldo en Bolsa Tigo',
                          path: '/bolsaTigo'
                        },
                        {
                          title: 'Apuesta por Tipo',
                          path: '/gerencia/tito',
                        }
                      ]
                    },
                  ]
                },
                {
                  title: 'Raspadita',
                  items: [
                    {
                      title: 'Asignaciones',
                      path: '/raspaditaAsignaciones',
                      icon: <GiTicket fontSize="small" />,
                      role: ['presidencia']
                    },
                    {
                      title: 'Stock',
                      icon: <GiTicket fontSize="small" />,
                      role: ['presidencia'],
                      children: [
                        {
                          title: 'Stock General',
                          path: '/stock/raspadita-tipo-juego',
                        },
                        {
                          title: 'Stock Días Asignados',
                          path: '/stock/raspadita-tipo-juego-dias-asignados',
                        },
                        {
                          title: 'Inventario',
                          path: '/raspaditas/inventario',
                        },

                      ]
                    },
                    {
                      title: 'Reportes Financiero',
                      path: '/raspadita',
                      icon: <BallotIcon fontSize="small" />,
                      role: ['raspa_operativa'],
                      children: [
                        {
                          title: 'Ganadores',
                          path: '/ganadores',
                        },
                        {
                          title: 'Facturación',
                          path: '/facturacion-raspadita',
                        },
                        {
                          title: 'Premios',
                          path: '/raspaditas/premios'
                        },
                        {
                          title: 'Movimiento Financiero',
                          path: '/raspaditaMovimientoFinanciero',
                          role: ['presidencia']
                        }
                      ]
                    },
                    {
                      title: 'Ventas por Corredor',
                      path: '/ventas-por-corredor',
                      icon: <GiTicket fontSize="small" />,
                      role: ['presidencia', 'gerencia']
                    },
                    {
                      title: 'Asignadas por Corredor',
                      path: '/asignadas-por-corredor',
                      icon: <GiTicket fontSize="small" />,
                      role: ['presidencia', 'gerencia']
                    }
                  ]
                },
                {
                  title: 'Sherlock',
                  items: [
                    {
                      title: 'Sherlock',
                      path: '/mode-holmes',
                      icon: <GiSherlockHolmes fontSize="small" />,
                      role: ['presidencia']
                    }
                  ]
                },
                {
                  title: 'Conajzar',
                  items: [
                    // {
                    //   title: 'Informe de cuenta del jugador',
                    //   path: '/conajzar/estadoJugador',
                    //   icon: <AssignmentIndIcon fontSize="small" />,
                    //   role: ['conajzar']
                    // },
                    {
                      title: 'Reporte Financiero',
                      path: '/conajzar/reporteFinanciero',
                      icon: <UsersIcon fontSize="small" />,
                      role: ['conajzar'],
                      children: [
                        {
                          title: 'Venta por agencias',
                          path: '/ventaAgencias',
                        },
                        {
                          title: 'Medios electrónicos',
                          path: '/mediosElectronicos',
                        },
                        {
                          title: 'Terminales',
                          path: '/mediosTerminales'
                        },
                      ]
                    },
                    {
                      title: 'Reportes del sistema',
                      path: '/conajzar/reporteSistema',
                      icon: <UserIcon fontSize="small" />,
                      role: ['conajzar']
                    },
                    {
                      title: 'Reporte de facturación Agencia',
                      path: '/conajzar/reporteAgencia',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['conajzar']
                    },
                    {
                      title: 'Reporte de facturación Diaria',
                      path: '/reporteFacturacionDiaria',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['conajzar']
                    },
                    {
                      title: 'Reporte de eventos significativos',
                      path: '/reporteDeEventos',
                      icon: <UsersIcon fontSize="small" />,
                      role: ['conajzar'],
                      children: [
                        {
                          title: 'Intentos de login fallidos',
                          path: '/loginFallidos',
                        },
                        {
                          title: 'Uptime del sistema',
                          path: '/uptimeSistema',
                        },
                        {
                          title: 'Anulaciones',
                          path: '/anulacionesEventos'
                        },
                        {
                          title: 'Update de software',
                          path: '/softwareUpdate'
                        },
                        {
                          title: 'Logs de parametros del sistema',
                          path: '/parametrosSistema'
                        },
                        {
                          title: 'Hearbeats',
                          path: '/hearbeatsEventos'
                        },
                        {
                          title: 'Registro de asistencias',
                          path: '/registroAsistencia'
                        },
                        {
                          title: 'Auditoria de Firewall',
                          path: '/auditoriaFirewall'
                        },
                        {
                          title: 'Reporte de acceso remoto',
                          path: '/accesoRemoto'
                        },
                        {
                          title: 'Apuestas Abortadas',
                          path: '/apuestasAbortadas'
                        },
                        {
                          title: 'Cambios de posibilidades Comision/Porcentaje',
                          path: '/cambiosPosibilidades'
                        },
                        {
                          title: 'Errores de incompatibilidad',
                          path: '/erroresIncompatibildad'
                        },
                      ]
                    },
                    {
                      title: 'Reporte de Pago del premio',
                      path: '/conajzar/reportePagoPremios',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['conajzar']
                    },
                    {
                      title: 'Reporte de premios superiores',
                      path: '/conajzar/reportesPagoSuperior',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['conajzar']
                    },
                    {
                      title: 'Reporte de sorteos',
                      path: '/informeLiquidaciones',
                      icon: <BallotIcon fontSize='small' />,
                      role: ['conajzar']
                    },
                  ]
                },
                {
                  title: 'Agencias',
                  items: [
                    {
                      title: 'Gestionar',
                      path: `/`,
                      className: 'menu-icon',
                      icon: <BriefcaseIcon fontSize="small" />,
                      role: ['tesoreria']
                    },
                  ]
                },
                {
                  title: 'Agencias',
                  items: [
                    {
                      title: 'Gestionar',
                      path: `/`,
                      className: 'menu-icon',
                      icon: <BriefcaseIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cumplimiento']
                    },
                    // TODO: planilla Agencias
                    // {
                    //   title: 'Planilla Agencias',
                    //   path: `/planillaAgencias`,
                    //   className: 'menu-icon',
                    //   icon: <BriefcaseIcon fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
                    // },
                    // {
                    //   title: 'Planilla',
                    //   path: `/planilla`,
                    //   icon: <DocumentText fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
                    // },
                    role?.includes('finanzas') ?
                      {
                        title: 'Estado de Recarga',
                        path: '/estadoRecargas',
                        icon: <SpeedIcon fontSize="small" />,
                        role: ['admin_juego', 'tdp_operativa', 'finanzas']
                      } : null,
                    role?.includes('finanzas') ? {
                      title: 'Bolsa Tigo',
                      path: '/bolsaTigo',
                      icon: <InsertChartIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'presidencia', 'gerencia']
                    } : null,
                    {
                      title: 'Planilla Premiados',
                      path: `/premiados/0`,
                      icon: <AccountBalanceWalletIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia']
                    },
                    // {
                    //   title: 'Planilla Jugadas',
                    //   path: `/jugadas`,
                    //   icon: <CasinoIcon fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
                    // },
                    {
                      title: 'Pago Premios',
                      path: `/pagoDePremios`,
                      icon: <GiReceiveMoney fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia']
                    },
                    {
                      title: 'Planilla Cobros',
                      path: `/cobros`,
                      icon: <ReceiptIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
                    },
                    {
                      title: 'Planilla Cobros Prueba',
                      path: `/cobros2`,
                      icon: <ReceiptIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
                    },
                    {
                      title: 'Deposito Bancario',
                      path: `/depositosAgencia`,
                      icon: <AccountBalanceIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
                    },
                    {
                      title: 'Lista de Usuarios',
                      path: '/users',
                      icon: <UserIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cumplimiento']
                    },
                    {
                      title: 'Usuarios Externos',
                      path: '/usuariosExternos',
                      icon: <RecentActorsIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'tdp_operativa']
                    },
                    {
                      title: 'Lista de Dispositivos',
                      path: '/devices',
                      icon: <Phone fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'agencia']
                    },
                    {
                      title: 'Deposito UMN',
                      path: '/depositoUmn',
                      icon: <ArchiveIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia']
                    },
                    // {
                    //   title: 'Extracto UMN',
                    //   path: '/extractoUmn',
                    //   icon: <AssignmentIcon fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia', 'umn']
                    // },
                    {
                      title: 'Boletas',
                      path: '/boletas',
                      icon: <TheatersIcon fontSize="small" />,
                      role: ['admin_juego', 'tdp_operativa']
                    },
                    role?.includes('admin_juego', 'tdp_operativa') ?
                      {
                        title: 'Productos Habilitados',
                        path: '/productoshabilitados',
                        icon: <AccountTreeIcon fontSize="small" />,
                        role: ['admin_juego', 'tdp_operativa']
                      } : null,
                    role?.includes('admin_juego', 'tdp_operativa') ?
                      {
                        title: 'Comisiones por Productos',
                        path: '/comisionesProductos',
                        icon: <LocalAtmIcon fontSize="small" />,
                        role: ['admin_juego', 'tdp_operativa']
                      } : null,
                    role?.includes('admin_juego', 'tdp_operativa', 'finanzas') ?
                      {
                        title: 'Estado de Recarga',
                        path: '/estadoRecargas',
                        icon: <SpeedIcon fontSize="small" />,
                        role: ['admin_juego', 'tdp_operativa', 'finanzas']
                      } : null,
                  ]
                },
                {
                  title: 'Stock',
                  items: [
                    {
                      title: 'Terminales',
                      path: `/stock/terminal`,
                      className: 'menu-icon',
                      icon: <SmartphoneIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'terminal']
                    },
                  ]
                },
                {
                  title: 'Cobrador',
                  items: [
                    {
                      title: 'Entregas',
                      path: '/entregas',
                      icon: <ShoppingBagIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'agencia', 'umn']
                    },
                    {
                      title: 'Estados Corredor',
                      path: '/estadosCorredor',
                      icon: <AssignmentIndIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'umn', 'agencia']
                    },
                    {
                      title: 'Cuenta',
                      path: '/profile',
                      icon: <UserIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    },
                    {
                      title: 'Tickets',
                      path: '/tickets',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    },
                    {
                      title: 'Billetaje',
                      path: '/billetaje',
                      icon: <DirectionsBusIcon fontSize='small' />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    }
                  ]
                },
                {
                  title: 'Operativa',
                  items: [
                    {
                      title: 'Quiniela',
                      path: '/SorteosBase',
                      icon: <UsersIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia'],
                      children: [
                        {
                          title: 'Anulaciones',
                          path: '/anulaciones',
                        },
                        role?.includes('agencia') ?
                          null : {
                            title: 'Anulaciones Premios',
                            path: '/anulaciones-premios',
                          },
                        {
                          title: 'Ajustes',
                          path: '/ajustes',
                        },
                        // {
                        //   title: 'Ajustes UMN',
                        //   path: '/ajustesUmn',
                        // },
                        {
                          title: 'Agenda sorteos',
                          path: '/SorteosBase'
                        },
                        {
                          title: 'Sorteos',
                          path: '/Sorteos'
                        },
                        {
                          title: 'Número limitados',
                          path: '/numerosLimitados'
                        }
                      ]
                    },
                    role?.includes('tdp_operativa') ? {
                      title: 'Raspadita',
                      path: '/RaspaditaOperativa',
                      icon: <UsersIcon fontSize="small" />,
                      role: ['admin_juego', 'tdp_operativa', 'raspa_operativa'],
                      children: [
                        // {
                        //   title: 'Corregir Premios',
                        //   path: 'raspadita/correcion-premios',
                        // },
                        {
                          title: 'Diferencia Premios',
                          path: 'raspadita/diferencia-premios',
                        },
                        {
                          title: 'Ventas',
                          path: '/raspadita',
                          icon: <BallotIcon fontSize="small" />,
                          role: ['admin_juego', 'tdp_operativa', 'raspa_operativa'],
                          children: [
                            {
                              title: 'Libros Vendidos',
                              path: 'raspadita/libros-vendidos',
                            },
                            {
                              title: 'Anulaciones',
                              path: 'raspadita/anulaciones',
                            }
                          ]
                        },
                        {
                          title: 'Asignaciones',
                          path: '/raspadita',
                          icon: <BallotIcon fontSize="small" />,
                          role: ['admin_juego', 'tdp_operativa', 'raspa_operativa'],
                          children: [
                            {
                              title: 'Libros Asignados / No Asignados',
                              path: 'raspadita/libros-asignaciones',
                            }
                          ]
                        }
                      ]
                    } : null,
                    role?.includes('tdp_operativa') ? {
                      title: 'Bolsa Tigo',
                      path: '/bolsaTigo',
                      icon: <InsertChartIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'presidencia', 'gerencia']
                    } : null,
                    role?.includes('tdp_operativa') ? {
                      title: 'Cubrida',
                      path: '/cubridas',
                      icon: <BeenhereIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'presidencia', 'gerencia']
                    } : null
                  ]
                },
                {
                  title: 'Raspadita Logística',
                  items: [
                    // {
                    //   title: 'Recepción',
                    //   path: '/raspadita',
                    //   icon: <CollectionIcon fontSize="small" />,
                    //   role: ['admin_juego', 'tdp_operativa', 'raspa_operativa'],
                    //   children: [
                    //     {
                    //       title: 'Importar Archivo',
                    //       path: '/stock/recepcion-juego',
                    //     }
                    //   ]
                    // },
                    {
                      title: 'Stock',
                      path: '/RaspaditaOperativa',
                      icon: <CollectionIcon fontSize="small" />,
                      role: ['admin_juego', 'tdp_operativa', 'raspa_operativa'],
                      children: [
                        {
                          title: 'Stock General',
                          path: '/stock/raspadita-tipo-juego',
                        },
                        {
                          title: 'Stock Pallets',
                          path: '/stock/pallets',
                        },
                        {
                          title: 'Días asignación',
                          path: '/raspaditas/raspadita-tipo-juego-dias-asignados'
                        },
                        {
                          title: 'Libros',
                          path: '/raspaditas/stock/libros',
                          icon: <CollectionIcon fontSize="small" />,
                          role: ['admin_juego', 'tdp_operativa', 'raspa_operativa', 'agencia', 'umn'],
                          children: [
                            {
                              title: 'Ventas',
                              path: '/stock/libros-vendidos',
                            }
                          ]
                        },
                      ]
                    },
                    {
                      title: 'Ventas',
                      path: '/RaspaditaOperativa',
                      icon: <CollectionIcon fontSize="small" />,
                      role: ['admin_juego', 'tdp_operativa', 'raspa_operativa'],
                      children: [
                        {
                          title: 'Boletas',
                          path: '/stock/raspadita-tipo-juego',
                        }
                      ]
                    },
                  ]
                },
                {
                  title: 'Seguimiento Financiero',
                  items: [
                    {
                      title: 'Resumen General',
                      path: '/resumenGeneral',
                      icon: <BriefcaseIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']
                    },
                    {
                      title: 'Estado de Cuenta Agencias',
                      path: '/gerencia/estadoCuentas',
                      icon: <AccountBalanceIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']
                    },
                    // {
                    //   title: 'Depositos',
                    //   path: '/resumenDepositos',
                    //   icon: <ArchiveIcon fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']
                    // },
                    // {
                    //   title: 'Ajustes',
                    //   path: '/resumenAjustes',
                    //   icon: <SettingsIcon fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'cobrador', 'umn', 'agencia']
                    // },
                  ]
                },
                {
                  title: 'Raspaditas',
                  items: [
                    {
                      title: 'Raspaditas',
                      path: '/raspaditas',
                      icon: <BallotIcon fontSize="small" />,
                      role: ['agencia', 'umn', 'admin_juego', 'tdp_operativa', 'finanzas'],
                      children: [
                        {
                          title: 'Recepcion de Cajas',
                          path: '/raspaditas/cajas',
                        },
                        {
                          title: 'Recepcion de Libros',
                          path: '/raspaditas/libros',
                        },
                        {
                          title: 'Asignación a Corredores',
                          path: '/raspaditas/corredores'
                        },
                        {
                          title: 'Reasignación a Corredores',
                          path: '/raspaditas/reasignacion-corredores'
                        },
                        {
                          title: 'Premios',
                          path: '/raspaditas/',
                          children: [
                            {
                              title: 'Boletas premiadas',
                              path: '/raspaditas/premios-agencia'
                            },
                            {
                              title: 'Boletas no premiadas',
                              path: '/raspaditas/no-premios-agencia'
                            },
                            {
                              title: 'Diferencia premios',
                              path: '/raspaditas/diferencia-premiadas'
                            },
                            {
                              title: 'Registrar premio',
                              path: '/raspaditas/registrar-premio'
                            }
                          ]
                        },
                        {
                          title: 'Stock',
                          path: '/raspaditas/',
                          children: [
                            {
                              title: 'Cajas',
                              path: '/raspaditas/stock/cajas',
                            },
                            {
                              title: 'Stock general',
                              path: '/raspaditas/stock'
                            },
                            {
                              title: 'Días asignación',
                              path: '/raspaditas/raspadita-tipo-juego-dias-asignados'
                            },
                            {
                              title: 'Inventario',
                              path: '/raspaditas/inventario',
                            }
                          ]
                        },
                        {
                          title: 'Stock Juegos',
                          path: '/raspaditas/stock-tipo-juego',
                        },
                        {
                          title: 'Boletas',
                          path: 'raspaditas',
                          children: [
                            {
                              title: 'Asignadas a corredores',
                              path: '/raspaditas/boletasAsignadasACorredores'
                            },
                            {
                              title: 'Asignadas por tipo Juego',
                              path: '/raspaditas/boletasAsignadasCorredores'
                            },
                            {
                              title: 'Vendidas por corredor',
                              path: '/raspaditas/boletasVendidasCorredores'
                            },
                            {
                              title: 'Vendidas por tipo Juego',
                              path: '/raspaditas/boletasVendidasTipoJuegoCorredor'
                            },
                            // {
                            //   title: 'Días por corredor',
                            //   path: '/raspaditas/boletasAsignadasCorredoresDias'
                            // }
                          ]
                        },
                        {
                          title: 'Estadisticas',
                          path: 'raspaditas',
                          children: [
                            {
                              title: 'Ventas de corredores',
                              path: '/raspaditas/ventasCorredores'
                            }
                          ]
                        },
                        {
                          title: 'Libros',
                          path: '/raspaditas/stock/libros',
                          icon: <CollectionIcon fontSize="small" />,
                          role: ['admin_juego', 'tdp_operativa', 'raspa_operativa', 'agencia', 'umn'],
                          children: [
                            {
                              title: 'Ventas',
                              path: '/stock/libros-vendidos',
                            }
                          ]
                        },
                        // {
                        //   title: 'Corredores',
                        //   path: '/raspaditas/corredores',
                        //   icon: <CollectionIcon fontSize="small" />,
                        //   role: ['admin_juego', 'tdp_operativa', 'raspa_operativa', 'agencia', 'umn'],
                        //   children: [
                        //     {
                        //       title: 'Estadísticas',
                        //       path: 'raspaditas/corredores/estadisticas',
                        //     }
                        //   ]
                        // },
                      ]
                    },
                  ]
                },
                {
                  title: 'Megaloto',
                  items: [
                    {
                      title: 'Megaloto',
                      path: '/megaloto',
                      icon: <BallotIcon fontSize="small" />,
                      role: ['admin_juego', 'tdp_operativa', 'agencia', 'umn'],
                      children: [
                        {
                          title: 'Registrar Premio',
                          path: '/megaloto/registrar-premio',
                        },
                        {
                          title: 'Anulaciones Megaloto',
                          path: '/megaloto/anulacion-megaloto'
                        },
                        {
                          title: 'Lista de Tickets',
                          path: '/megaloto/megaloto-tickets'
                        }
                      ]
                    },
                  ]
                },
                {
                  title: 'Western Union',
                  items: [
                    {
                      title: 'Western Union',
                      path: '/westerUnion/verificar-mtcn',
                      icon: <MonetizationOn fontSize="small" />,
                      role: ['bridget'],
                    },
                  ]
                },
                {
                  title: 'Prestamos Teete',
                  items: [
                    {
                      title: 'Prestamos Teete',
                      path: '/prestamos-teete',
                      icon: <MonetizationOn fontSize="small" />,
                      role: [ 'umn', 'agencia'],
                      children: [
                         {
                           title: 'Cuotas',
                           path: '/prestamos-teete/cuotas',
                         }
                      ]
                    }
                  ]
                },
                {
                  title: 'Raspadita asignacion Libro a Umn',
                  items: [
                    {
                      title: 'Asignacion a UMN',
                      path: `/raspaditas/asignacion-umn`,
                      className: 'menu-icon',
                      icon: <BallotIcon fontSize="small" />,
                      role: ['admin_juego', 'agencia',]
                    },
                  ]
                },
                {
                  title: 'Raspadita Devolucion Agencia',
                  items: [
                    {
                      title: 'Devolver Libro Agencia ',
                      path: `/raspaditas/devolucion-libro-umn`,
                      className: 'menu-icon',
                      icon: <BallotIcon fontSize="small" />,
                      role: ['admin_juego', 'agencia']
                    },
                  ]
                },
                {
                  title: 'Raspadita (AG departamental)',
                  items: [
                    {
                      title: 'Recepción de caja',
                      path: `/raspaditas/recepcion-caja`,
                      className: 'menu-icon',
                      icon: <BallotIcon fontSize="small" />,
                      role: ['admin_juego', 'agencia',]
                    },
                  ]
                },
              ];
  } else {
    sections = role?.includes('tdp_gerencia', 'presidencia') ?
      [
        {
          title: 'Gerencia',
          items: [
            {
              title: 'General',
              path: '/gerencia',
              icon: <HomeIcon fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia']
            },
            {
              title: 'Diferencia',
              path: '/gerencia/diferencias',
              icon: <ExposureIcon fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia']
            },
            {
              title: 'Cubrida',
              path: '/gerencia/cubridas',
              icon: <BeenhereIcon fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia']
            }
          ]
        },
        {
          title: "Facturación",
          items: [
            {
              title: 'Facturación Telefonía',
              path: '/facturacion-telefonia',
              icon: <HomeIcon fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia', 'admin_juego']
            },
          ]
        },
        {
          title: 'Control de POS',
          items: [
            {
              title: 'Control de POS',
              path: '/control-pos',
              icon: <HomeIcon fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia', 'umn', 'tdp_operativa', 'finanzas', 'tesoreria', 'conajzar', 'conajzar_raspa', 'raspa_operativa', 'corredor', 'cobrador', 'raspa_gerencia', 'agencia']
            },
            {
              title: 'Geolocalización',
              path: '/geo',
              icon: <FaMapMarkedAlt fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia', 'umn', 'tdp_operativa', 'finanzas', 'tesoreria', 'conajzar', 'conajzar_raspa', 'raspa_operativa', 'corredor', 'cobrador', 'raspa_gerencia', 'agencia']
            }
          ]
        },
        {
          title: 'Reportes',
          items: [

            {
              title: 'Reportes',
              path: '/conajzar/reporteFinanciero',
              icon: <UsersIcon fontSize="small" />,
              role: ['tdp_gerencia', 'presidencia'],
              children: [
                {
                  title: 'Montos por Boletas',
                  path: '/boletasPorMonto',
                },
                {
                  title: 'Historial de Sorteos',
                  path: '/historialDeSorteos',
                },
                {
                  title: 'Ocurrencias',
                  path: '/ocurrenciasGlobales'
                },
                {
                  title: 'Análisis de ocurrencia',
                  path: '/ocurrenciaGraficos'
                },
                {
                  title: 'Ocurrencias de Tómbola',
                  path: '/ocurrenciasTombola'
                },
                {
                  title: 'Bolillero',
                  path: '/bolillero'
                },
              ]
            },
          ]
        },
        {
          title: 'Raspadita',
          items: [
            {
              title: 'Asignaciones',
              path: '/raspaditaAsignaciones',
              icon: <GiTicket fontSize="small" />,
              role: ['presidencia']
            },
            {
              title: 'Movimiento Financiero',
              path: '/raspaditaMovimientoFinanciero',
              icon: <GiTicket fontSize="small" />,
              role: ['presidencia']
            }
          ]
        },
        {
          title: 'Reporte Raspadita',
          items: [
            {
              title: 'Reportes Raspadita',
              path: '/raspadita',
              icon: <BallotIcon fontSize="small" />,
              role: ['presidencia', 'tdp_gerencia'],
              children: [
                {
                  title: 'Ganadores',
                  path: '/ganadores',
                },
                {
                  title: 'Facturación',
                  path: '/facturacion-raspadita-gerencia',
                }
              ]
            }
          ]
        },
        {
          title: 'Gerencia',
          items: [
            {
              title: 'Sherlock',
              path: '/mode-holmes',
              icon: <GiSherlockHolmes fontSize="small" />,
              role: ['presidencia']
            }
          ]
        },
      ]
      :
      sections = role?.includes('raspa_operativa', 'admin_juego', 'tdp_operativa') ?
        [
          {
            title: 'Reporte Raspadita',
            items: [
              {
                title: 'Reportes Raspadita',
                path: '/raspadita',
                icon: <BallotIcon fontSize="small" />,
                role: ['raspa_operativa'],
                children: [
                  {
                    title: 'Ganadores',
                    path: '/ganadores',
                  },
                  {
                    title: 'Facturación',
                    path: '/facturacion-raspadita',
                  }
                ]
              }
            ]
          },
        ]
        :
        role?.includes('conajzar') ?
          [
            {
              title: 'Conajzar',
              items: [
                // {
                //   title: 'Informe de cuenta del jugador',
                //   path: '/estadoJugador',
                //   icon: <AssignmentIndIcon fontSize="small" />,
                //   role: ['conajzar']
                // },
                {
                  title: 'Reporte Financiero',
                  path: '/reporteFinanciero',
                  icon: <UsersIcon fontSize="small" />,
                  role: ['conajzar'],
                  children: [
                    {
                      title: 'Venta por agencias',
                      path: '/ventaAgencias',
                    },
                    {
                      title: 'Medios electrónicos',
                      path: '/mediosElectronicos',
                    },
                    {
                      title: 'Terminales',
                      path: '/mediosTerminales'
                    },
                  ]
                },
                {
                  title: 'Reportes del sistema',
                  path: '/reporteSistema',
                  icon: <UserIcon fontSize="small" />,
                  role: ['conajzar']
                },
                {
                  title: 'Reporte de facturación Agencia',
                  path: '/reporteAgencia',
                  icon: <ConfirmationNumberIcon fontSize='small' />,
                  role: ['conajzar']
                },
                {
                  title: 'Reporte de facturación Diaria',
                  path: '/reporteFacturacionDiaria',
                  icon: <ConfirmationNumberIcon fontSize='small' />,
                  role: ['conajzar']
                },
                {
                  title: 'Reporte de eventos significativos',
                  path: '/reporteDeEventos',
                  icon: <UsersIcon fontSize="small" />,
                  role: ['conajzar'],
                  children: [
                    {
                      title: 'Intentos de login fallidos',
                      path: '/loginFallidos',
                    },
                    {
                      title: 'Uptime del sistema',
                      path: '/uptimeSistema',
                    },
                    {
                      title: 'Anulaciones',
                      path: '/anulacionesEventos'
                    },
                    {
                      title: 'Update de software',
                      path: '/softwareUpdate'
                    },
                    {
                      title: 'Logs de parametros del sistema',
                      path: '/parametrosSistema'
                    },
                    {
                      title: 'Hearbeats',
                      path: '/hearbeatsEventos'
                    },
                    {
                      title: 'Registro de asistencias',
                      path: '/registroAsistencia'
                    },
                    {
                      title: 'Auditoria de Firewall',
                      path: '/auditoriaFirewall'
                    },
                    {
                      title: 'Reporte de acceso remoto',
                      path: '/accesoRemoto'
                    },
                    {
                      title: 'Apuestas Abortadas',
                      path: '/apuestasAbortadas'
                    },
                    {
                      title: 'Cambios de posibilidades Comision/Porcentaje',
                      path: '/cambiosPosibilidades'
                    },
                    {
                      title: 'Errores de incompatibilidad',
                      path: '/erroresIncompatibildad'
                    },
                  ]
                },
                {
                  title: 'Reporte de Pago del premio',
                  path: '/reportePagoPremios',
                  icon: <ConfirmationNumberIcon fontSize='small' />,
                  role: ['conajzar']
                },
                {
                  title: 'Reporte de premios superiores',
                  path: '/reportesPagoSuperior',
                  icon: <ConfirmationNumberIcon fontSize='small' />,
                  role: ['conajzar']
                },
                {
                  title: 'Reporte de sorteos',
                  path: '/informeLiquidaciones',
                  icon: <BallotIcon fontSize='small' />,
                  role: ['conajzar']
                },
                {
                  title: 'Reporte de Raspaditas',
                  path: '/reporteDeRaspadita',
                  icon: <BallotIcon fontSize='small' />,
                  role: ['conajzar'],
                  children: [
                    {
                      title: 'Facturación',
                      path: '/facturacion-raspadita',
                    },
                    {
                      title: 'Ganadores',
                      path: '/ganadores',
                    }
                  ]
                },
              ]
            },
          ] :
          role?.includes('conajzar_raspa') ?
            [
              {
                title: 'Raspadita',
                items: [
                  {
                    title: 'Reporte de Raspaditas',
                    path: '/reporteDeRaspadita',
                    icon: <BallotIcon fontSize='small' />,
                    role: ['conajzar_raspa'],
                    children: [
                      {
                        title: 'Facturación',
                        path: '/facturacion-raspadita',
                      },
                      {
                        title: 'Ganadores',
                        path: '/ganadores',
                      }
                    ]
                  },
                ]
              },
            ] :
            role?.includes('cobrador') ?
              [
                {
                  title: 'Cobrador',
                  items: [
                    {
                      title: 'Entregas',
                      path: '/entregas',
                      icon: <ShoppingBagIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    },
                    {
                      title: 'Estados Corredor',
                      path: '/estadosCorredor',
                      icon: <AssignmentIndIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'umn', 'agencia']
                    },
                    {
                      title: 'Cuenta',
                      path: '/profile',
                      icon: <UserIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    },
                    {
                      title: 'Tickets',
                      path: '/tickets',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    },
                    {
                      title: 'Billetaje',
                      path: '/billetaje',
                      icon: <DirectionsBusIcon fontSize='small' />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    }
                  ]
                },
              ] :
              [
                {
                  title: 'Gerencia',
                  items: [
                    {
                      title: 'General',
                      path: '/gerencia',
                      icon: <HomeIcon fontSize="small" />,
                      role: ['tdp_gerencia', 'presidencia']
                    },
                    {
                      title: 'Diferencia',
                      path: '/gerencia/diferencias',
                      icon: <ExposureIcon fontSize="small" />,
                      role: ['tdp_gerencia', 'presidencia']
                    },
                    {
                      title: 'Cubrida',
                      path: '/gerencia/cubridas',
                      icon: <BeenhereIcon fontSize="small" />,
                      role: ['tdp_gerencia', 'presidencia']
                    }
                  ]
                },
                {
                  title: 'Reportes',
                  items: [
                    {
                      title: 'Reportes',
                      path: '/conajzar/reporteFinanciero',
                      icon: <UsersIcon fontSize="small" />,
                      role: ['tdp_gerencia', 'presidencia'],
                      children: [
                        {
                          title: 'Montos por Boletas',
                          path: '/boletasPorMonto',
                        },
                        {
                          title: 'Historial de Sorteos',
                          path: '/historialDeSorteos',
                        },
                        {
                          title: 'Ocurrencias',
                          path: '/ocurrenciasGlobales'
                        },
                        {
                          title: 'Análisis de ocurrencia',
                          path: '/ocurrenciaGraficos'
                        },
                        {
                          title: 'Ocurrencias de Tómbola',
                          path: '/ocurrenciasTombola'
                        },
                        {
                          title: 'Bolillero',
                          path: '/bolillero'
                        },
                      ]
                    },
                  ]
                },
                {
                  title: 'Raspadita',
                  items: [
                    {
                      title: 'Asignaciones',
                      path: '/raspaditaAsignaciones',
                      icon: <GiTicket fontSize="small" />,
                      role: ['presidencia']
                    },
                    {
                      title: 'Movimiento Financiero',
                      path: '/raspaditaMovimientoFinanciero',
                      icon: <GiTicket fontSize="small" />,
                      role: ['presidencia']
                    }
                  ]
                },
                {
                  title: 'Reporte Raspadita',
                  items: [
                    {
                      title: 'Reportes Raspadita',
                      path: '/raspadita',
                      icon: <BallotIcon fontSize="small" />,
                      role: ['presidencia', 'tdp_gerencia'],
                      children: [
                        {
                          title: 'Ganadores',
                          path: '/ganadores',
                        },
                        {
                          title: 'Facturación',
                          path: '/facturacion-raspadita-gerencia',
                        }
                      ]
                    }
                  ]
                },
                {
                  title: 'Sherlock',
                  items: [
                    {
                      title: 'Sherlock',
                      path: '/mode-holmes',
                      icon: <GiSherlockHolmes fontSize="small" />,
                      role: ['presidencia']
                    }
                  ]
                },
                {
                  title: 'Conajzar',
                  items: [
                    // {
                    //   title: 'Informe de cuenta del jugador',
                    //   path: '/conajzar/estadoJugador',
                    //   icon: <AssignmentIndIcon fontSize="small" />,
                    //   role: ['conajzar']
                    // },
                    {
                      title: 'Reporte Financiero',
                      path: '/conajzar/reporteFinanciero',
                      icon: <UsersIcon fontSize="small" />,
                      role: ['conajzar'],
                      children: [
                        {
                          title: 'Venta por agencias',
                          path: '/ventaAgencias',
                        },
                        {
                          title: 'Medios electrónicos',
                          path: '/mediosElectronicos',
                        },
                        {
                          title: 'Terminales',
                          path: '/mediosTerminales'
                        },
                      ]
                    },
                    {
                      title: 'Reportes del sistema',
                      path: '/conajzar/reporteSistema',
                      icon: <UserIcon fontSize="small" />,
                      role: ['conajzar']
                    },
                    {
                      title: 'Reporte de facturación Agencia',
                      path: '/conajzar/reporteAgencia',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['conajzar']
                    },
                    {
                      title: 'Reporte de facturación Diaria',
                      path: '/reporteFacturacionDiaria',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['conajzar']
                    },
                    {
                      title: 'Reporte de eventos significativos',
                      path: '/reporteDeEventos',
                      icon: <UsersIcon fontSize="small" />,
                      role: ['conajzar'],
                      children: [
                        {
                          title: 'Intentos de login fallidos',
                          path: '/loginFallidos',
                        },
                        {
                          title: 'Uptime del sistema',
                          path: '/uptimeSistema',
                        },
                        {
                          title: 'Anulaciones',
                          path: '/anulacionesEventos'
                        },
                        {
                          title: 'Update de software',
                          path: '/softwareUpdate'
                        },
                        {
                          title: 'Logs de parametros del sistema',
                          path: '/parametrosSistema'
                        },
                        {
                          title: 'Hearbeats',
                          path: '/hearbeatsEventos'
                        },
                        {
                          title: 'Registro de asistencias',
                          path: '/registroAsistencia'
                        },
                        {
                          title: 'Auditoria de Firewall',
                          path: '/auditoriaFirewall'
                        },
                        {
                          title: 'Reporte de acceso remoto',
                          path: '/accesoRemoto'
                        },
                        {
                          title: 'Apuestas Abortadas',
                          path: '/apuestasAbortadas'
                        },
                        {
                          title: 'Cambios de posibilidades Comision/Porcentaje',
                          path: '/cambiosPosibilidades'
                        },
                        {
                          title: 'Errores de incompatibilidad',
                          path: '/erroresIncompatibildad'
                        },
                      ]
                    },
                    {
                      title: 'Reporte de Pago del premio',
                      path: '/conajzar/reportePagoPremios',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['conajzar']
                    },
                    {
                      title: 'Reporte de premios superiores',
                      path: '/conajzar/reportesPagoSuperior',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['conajzar']
                    },
                    {
                      title: 'Reporte de sorteos',
                      path: '/informeLiquidaciones',
                      icon: <BallotIcon fontSize='small' />,
                      role: ['conajzar']
                    },
                  ]
                },
                {
                  title: 'Agencias',
                  items: [
                    {
                      title: 'Gestionar',
                      path: `/`,
                      className: 'menu-icon',
                      icon: <BriefcaseIcon fontSize="small" />,
                      role: ['tesoreria']
                    },
                  ]
                },
                {
                  title: 'Agencias',
                  items: [
                    {
                      title: 'Gestionar',
                      path: `/`,
                      className: 'menu-icon',
                      icon: <BriefcaseIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cumplimiento']
                    },
                    // TODO: planilla Agencias
                    // {
                    //   title: 'Planilla Agencias',
                    //   path: `/planillaAgencias`,
                    //   className: 'menu-icon',
                    //   icon: <BriefcaseIcon fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
                    // },
                    // {
                    //   title: 'Planilla',
                    //   path: `/planilla`,
                    //   icon: <DocumentText fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
                    // },
                    {
                      title: 'Planilla Premiados',
                      path: `/premiados/0`,
                      icon: <AccountBalanceWalletIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia']
                    },
                    // {
                    //   title: 'Planilla Jugadas',
                    //   path: `/jugadas`,
                    //   icon: <CasinoIcon fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'umn', 'agencia']
                    // },
                    {
                      title: 'Planilla Cobros',
                      path: `/cobros`,
                      icon: <ReceiptIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
                    },
                    {
                      title: 'Planilla Cobros Prueba',
                      path: `/cobros2`,
                      icon: <ReceiptIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
                    },
                    {
                      title: 'Deposito Bancario',
                      path: `/depositosAgencia`,
                      icon: <AccountBalanceIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'cobrador']
                    },
                    {
                      title: 'Lista de Usuarios',
                      path: '/users',
                      icon: <UserIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia']
                    },
                    {
                      title: 'Usuarios Externos',
                      path: '/usuariosExternos',
                      icon: <RecentActorsIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'tdp_operativa']
                    },
                    {
                      title: 'Lista de Dispositivos',
                      path: '/devices',
                      icon: <Phone fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'agencia']
                    },
                    {
                      title: 'Deposito UMN',
                      path: '/depositoUmn',
                      icon: <ArchiveIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia']
                    },
                    // {
                    //   title: 'Extracto UMN',
                    //   path: '/extractoUmn',
                    //   icon: <AssignmentIcon fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'tdp_operativa', 'agencia', 'umn']
                    // },
                    {
                      title: 'Boletas',
                      path: '/boletas',
                      icon: <TheatersIcon fontSize="small" />,
                      role: ['admin_juego', 'tdp_operativa']
                    },

                  ]
                },
                {
                  title: 'Stock',
                  items: [
                    {
                      title: 'Terminales',
                      path: `/stock/terminal`,
                      className: 'menu-icon',
                      icon: <SmartphoneIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia', 'terminal']
                    },
                  ]
                },
                {
                  title: 'Cobrador',
                  items: [
                    {
                      title: 'Entregas',
                      path: '/entregas',
                      icon: <ShoppingBagIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'agencia', 'umn']
                    },
                    {
                      title: 'Estados Corredor',
                      path: '/estadosCorredor',
                      icon: <AssignmentIndIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas', 'umn', 'agencia']
                    },
                    {
                      title: 'Cuenta',
                      path: '/profile',
                      icon: <UserIcon fontSize="small" />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    },
                    {
                      title: 'Tickets',
                      path: '/tickets',
                      icon: <ConfirmationNumberIcon fontSize='small' />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    },
                    {
                      title: 'Billetaje',
                      path: '/billetaje',
                      icon: <DirectionsBusIcon fontSize='small' />,
                      role: ['cobrador', 'admin_juego', 'finanzas', 'tdp_operativa', 'finanzas']
                    }
                  ]
                },
                {
                  title: 'Operativa',
                  items: [
                    {
                      title: 'Quiniela',
                      path: '/SorteosBase',
                      icon: <UsersIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia'],
                      children: [
                        {
                          title: 'Anulaciones',
                          path: '/anulaciones',
                        },
                        {
                          title: 'Anulaciones Premios',
                          path: '/anulaciones-premios',
                        },
                        {
                          title: 'Ajustes',
                          path: '/ajustes',
                        },
                        // {
                        //   title: 'Ajustes UMN',
                        //   path: '/ajustesUmn',
                        // },
                        {
                          title: 'Agenda sorteos',
                          path: '/SorteosBase'
                        },
                        {
                          title: 'Sorteos',
                          path: '/Sorteos'
                        },
                        {
                          title: 'Número limitados',
                          path: '/numerosLimitados'
                        }
                      ]
                    },
                    {
                      title: 'Raspadita',
                      path: '/RaspaditaOperativa',
                      icon: <UsersIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'umn', 'agencia'],
                      children: [
                        // {
                        //   title: 'Corregir Premios',
                        //   path: 'raspadita/correcion-premios',
                        // },
                        {
                          title: 'Diferencia Premios',
                          path: 'raspadita/diferencia-premios',
                        },
                        {
                          title: 'Ventas',
                          path: '/raspadita',
                          icon: <BallotIcon fontSize="small" />,
                          role: ['admin_juego', 'tdp_operativa', 'raspa_operativa'],
                          children: [
                            {
                              title: 'Libros Vendidos',
                              path: 'raspadita/libros-vendidos',
                            }
                          ]
                        },
                        {
                          title: 'Asignaciones',
                          path: '/raspadita',
                          icon: <BallotIcon fontSize="small" />,
                          role: ['admin_juego', 'tdp_operativa', 'raspa_operativa'],
                          children: [
                            {
                              title: 'Libros Asignados / No Asignados',
                              path: 'raspadita/libros-asignaciones',
                            }
                          ]
                        }
                      ]
                    }
                  ]
                },
                {
                  title: 'Seguimiento Financiero',
                  items: [
                    {
                      title: 'Resumen General',
                      path: '/resumenGeneral',
                      icon: <BriefcaseIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']
                    },
                    {
                      title: 'Estado de Cuenta Agencias',
                      path: '/gerencia/estadoCuentas',
                      icon: <AccountBalanceIcon fontSize="small" />,
                      role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']
                    },
                    // {
                    //   title: 'Depositos',
                    //   path: '/resumenDepositos',
                    //   icon: <ArchiveIcon fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'umn', 'agencia', 'cobrador']
                    // },
                    // {
                    //   title: 'Ajustes',
                    //   path: '/resumenAjustes',
                    //   icon: <SettingsIcon fontSize="small" />,
                    //   role: ['admin_juego', 'finanzas', 'tdp_gerencia', 'cobrador', 'umn', 'agencia']
                    // },
                  ]
                },
                {
                  title: 'Raspaditas',
                  items: [
                    {
                      title: 'Raspaditas',
                      path: '/raspaditas',
                      icon: <BallotIcon fontSize="small" />,
                      role: ['agencia', 'umn', 'admin_juego', 'tdp_operativa', 'finanzas'],
                      children: [
                        {
                          title: 'Recepcion de Cajas',
                          path: '/raspaditas/cajas',
                        },
                        {
                          title: 'Recepcion de Libros',
                          path: '/raspaditas/libros',
                        },
                        {
                          title: 'Asignación a Corredores',
                          path: '/raspaditas/corredores'
                        },
                        {
                          title: 'Reasignación a Corredores',
                          path: '/raspaditas/reasignacion-corredores'
                        },
                        {
                          title: 'Premios',
                          path: '/raspaditas',
                          children: [
                            {
                              title: 'Boletas premiadas',
                              path: '/raspaditas/premios-agencia'
                            },
                            {
                              title: 'Boletas no premiadas',
                              path: '/raspaditas/no-premios-agencia'
                            },
                            {
                              title: 'Diferencia premios',
                              path: '/raspaditas/diferencia-premiadas'
                            },
                            {
                              title: 'Registrar premio',
                              path: '/raspaditas/registrar-premio'
                            }
                          ]
                        },
                        {
                          title: 'Stock',
                          path: '/raspaditas/stock'
                        },
                        {
                          title: 'Inventario',
                          path: '/raspaditas/inventario',
                        },
                        {
                          title: 'Stock Juegos',
                          path: '/raspaditas/stock-tipo-juego'
                        },
                        {
                          title: 'Boletas',
                          path: '/raspaditas',
                          children: [
                            {
                              title: 'Asignadas a corredores',
                              path: '/raspaditas/boletasAsignadasACorredores'
                            },
                            {
                              title: 'Asignadas por tipo Juego',
                              path: '/raspaditas/boletasAsignadasCorredores'
                            },
                            {
                              title: 'Vendidas por corredor',
                              path: '/raspaditas/boletasVendidasCorredores'
                            },
                            {
                              title: 'Vendidas por tipo Juego',
                              path: '/raspaditas/boletasVendidasTipoJuegoCorredor'
                            },
                            // {
                            //   title: 'Días por corredor',
                            //   path: '/raspaditas/boletasAsignadasCorredoresDias'
                            // }
                          ]
                        },
                        {
                          title: 'Estadisticas',
                          path: 'raspaditas',
                          children: [
                            {
                              title: 'Ventas de corredores',
                              path: '/raspaditas/ventasCorredores'
                            }
                          ]
                        },
                        {
                          title: 'Libros',
                          path: '/raspaditas/stock/libros',
                          icon: <CollectionIcon fontSize="small" />,
                          role: ['admin_juego', 'tdp_operativa', 'raspa_operativa', 'agencia', 'umn'],
                          children: [
                            {
                              title: 'Ventas',
                              path: '/stock/libros-vendidos',
                            }
                          ]
                        },
                      ]
                    },
                  ]
                },
                {
                  title: 'Megaloto',
                  items: [
                    {
                      title: 'Megaloto',
                      path: '/megaloto',
                      icon: <BallotIcon fontSize="small" />,
                      role: ['admin_juego', 'tdp_operativa', 'agencia', 'umn'],
                      children: [
                        {
                          title: 'Registrar Premio',
                          path: '/megaloto/registrar-premio',
                        },
                        {
                          title: 'Anulaciones Megaloto',
                          path: '/megaloto/anulacion-megaloto'
                        }
                      ]
                    },
                  ]
                },
                {
                  title: 'Western Union',
                  items: [
                    {
                      title: 'Western Union',
                      path: '/westerUnion/verificar-mtcn',
                      icon: <MonetizationOn fontSize="small" />,
                      role: ['bridget'],
                    },
                  ]
                },
                {
                  title: "Facturación",
                  items: [
                    {
                      title: 'Facturación Telefonía',
                      path: '/facturacion-telefonia',
                      icon: <HomeIcon fontSize="small" />,
                      role: ['tdp_gerencia', 'presidencia', 'umn', 'tdp_operativa', 'finanzas', 'tesoreria', 'conajzar', 'conajzar_raspa', 'raspa_operativa', 'corredor', 'cobrador', 'raspa_gerencia', 'agencia', 'admin_juego']
                    },
                  ]
                },
                // {
                //   title: 'Prestamos Teete',
                //   items: [
                //     {
                //       title: 'Cuotas',
                //       path: '/prestamosTeete/cuotas',
                //       icon: <MonetizationOn fontSize="small" />,
                //     }
                //   ]
                // }
                // {
                //   title: 'Raspadita asignacion corredor',
                //   items: [
                //     {
                //       title: 'Asignacion a UMN',
                //       path: `/raspaditas/asignacion-umn`,
                //       className: 'menu-icon',
                //       icon: <BallotIcon fontSize="small" />,
                //       role: ['admin_juego', 'agencia',]
                //     },
                //   ]
                // },
              ];
  }

  console.log('data umn: ', dataUmn)
  console.log('data agencia: ', agenciaNumber)

  const handleChangeUMN = (event) => {
    if (event.target.value === 0 || event.target.value === '0') {
      setUMN(umnS?.map((u) => Number(u.id)));
      // setAgenciaValue([agenciaNumber?.umn?.agencia_numero])
    } else {
      setUMN(Number(event.target.value));
      // setAgenciaValue([agenciaNumber?.umn?.agencia_numero])
    }
  };

  const handleChangeZona = (event) => {
    if (event.target.value === 0 || event.target.value === '0') {
      setZona(getZonasByAgencia?.map((u) => Number(u.zona)))

      if (role?.includes('agencia') && !role?.includes('tdp_operativa')) {
        umnS = user.umns
        setUMN(umnS?.map((u) => Number(u.id)));
        // setAgenciaValue([agenciaNumber?.umn?.agencia_numero])
      }
    } else {
      setZona([Number(event.target.value)]);
      if ((role?.includes('agencia') || role?.includes('finanzas')) || role?.includes('tesoreria') && !role?.includes('tdp_operativa')) {
        umnS = user.umns
        umnS = umnS.filter(umn => umn.zona === parseInt(event.target.value, 10))
        setUMN(umnS?.map((u) => Number(u.id)));
        // setAgenciaValue([agenciaNumber?.umn?.agencia_numero])
      }
    }
  };

  // useEffect(() => {
  //   // refetch if change zona
  //   refetch({agencia, zona})
  // }, [zona])

  useEffect(() => {
    setZona([0])
  }, [agencia])

  useEffect(() => {
    console.log('agencia value by', agenciaNumber?.umn?.agencia_numero)
    if (umnS !== undefined) {
      if (umnS?.length > 1) {
        setUMN(umnS?.map((u) => Number(u.id)));
        // setAgenciaValue([agenciaNumber?.umn?.agencia_numero])
      } else {
        setUMN(umnS?.map((u) => Number(u.id)))
        // setAgenciaValue([agenciaNumber?.umn?.agencia_numero])
      }
      // navigate('/')
    }
  }, [umnS])

  // useEffect(() => {
  //   setAgenciaValue([agenciaNumber?.umn?.agencia_numero])
  // }, [agenciaNumber?.umn?.agencia_numero])

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            {/* <RouterLink to="/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink> */}
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.plan}
                </Link>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
              mt: 2,
              position: "relative"
            }}
          >
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {
                  agencia?.length > 2
                    ?
                    "Agencia: Todas"
                    :
                    `Agencia: ${agenciaNumber?.umn?.agencia_numero ? `${agenciaNumber?.umn?.agencia_numero}` : 'cargando...'}`
                }
              </Typography>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {
                  UMN?.length > 2
                    ?
                    "UMN: Todas"
                    :
                    `UMN: ${agenciaNumber?.umn?.numero ? `${agenciaNumber?.umn?.numero} ${agenciaNumber?.umn?.nombre}` : 'cargando...'}`
                }
                {
                  UMN?.length > 2
                    ?
                    null
                    :
                    <Box sx={{
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                      width: "30px",
                      borderRadius: "20px",
                      color: "white",
                      backgroundColor: "#003c8f",
                      fontSize: "10px",
                      textAlign: "center"
                    }}>
                      {umnId ? `${umnId}` : 'cargando...'}
                    </Box>
                }
              </Typography>
            </Box>
          </Box>
          {role?.includes('presidencia') || role?.includes('admin_juego') || role?.includes('tdp_gerencia') ?
            <Box sx={{ marginTop: 2 }}>
              <Button sx={{ width: "248px", borderRadius: "8px" }}
                variant="contained"
                color="primary"
                href="/gerencia/proyecciones">
                Proyecciones
              </Button>
            </Box>
            : null}
          <Box>
            <DateConsumer>
              {({ actualDateValue, setDateValue, setDate }) => {
                searchDate = () => {
                  setDate(actualDateValue)
                }
                return (
                  <Grid container>
                    <List
                      style={{ width: '100%', position: 'relative', paddingTop: 20 }}
                      subheader={<ListSubheader sx={{ backgroundColor: 'background.default' }}
                        color='inherit'>Fecha</ListSubheader>}>
                      <Grid
                        container
                        sx={{ marginTop: 1 }}>
                        <DateAndTimePickers
                          actualDate={actualDateValue}
                          setDate={setDateValue} />
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-around"
                      >
                        <Button
                          onClick={() => {
                            const startDate = dayjs(actualDateValue.startDate).subtract(1, 'day').format('YYYY-MM-DD');
                            const endDate = dayjs(actualDateValue.endDate).subtract(1, 'day').format('YYYY-MM-DD');
                            setDate({ startDate, endDate });
                            setDateValue({ startDate, endDate });
                          }}
                        >
                          <IconButton>
                            <ArrowLeft
                              fontSize="small"
                              style={{ color: "blue" }}
                            />
                          </IconButton>
                        </Button>
                        <Button
                          onClick={() => {
                            const startDate = dayjs(actualDateValue.startDate).add(1, 'day').format('YYYY-MM-DD');
                            const endDate = dayjs(actualDateValue.endDate).add(1, 'day').format('YYYY-MM-DD');
                            setDate({ startDate, endDate });
                            setDateValue({ startDate, endDate });
                          }}
                        >
                          <IconButton>
                            <ArrowRight
                              fontSize="small"
                              style={{ color: "blue" }}
                            />
                          </IconButton>
                        </Button>
                      </Grid>
                    </List>
                  </Grid>
                )
              }}
            </DateConsumer>
            {role?.includes('tdp_gerencia') ?
              null
              :
              <Grid container  >
                <List style={{ width: '100%', maxWidth: 360, position: 'relative' }}>
                  <Grid container
                    sx={{ marginTop: 1 }}>
                    {
                      agenciaNumber?.umn?.zona === 0 && (role.includes('agencia') || role.includes('tdp_operativa')) || role?.includes('presidencia') || role?.includes('umn')
                        ? null :
                        <Grid item>
                          <TextField
                            sx={{ width: 175, marginBottom: 2, marginLeft: 3, marginTop: 2 }}
                            label="Zonas"
                            select
                            SelectProps={{ native: true }}
                            variant="outlined"
                            name="zona"
                            onChange={handleChangeZona}
                            value={zona}
                          >
                            <option
                              key={0}
                              value={0}
                            >
                              Todas
                            </option>
                            {
                              getZonasByAgencia?.map((item) => (
                                <option
                                  key={item.zona}
                                  value={item?.zona}
                                >
                                  {`Zona ${item?.zona}`}
                                </option>
                              ))
                            }
                          </TextField>
                        </Grid>
                    }
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {agencia?.length > 1
                      ?
                      <Grid item >
                        <Label
                          color="success"
                        >
                          Todas las UMN seleccionadas
                        </Label>
                      </Grid>
                      // eslint-disable-next-line no-nested-ternary
                      : UMN?.length === 1 && agencia?.length === 1 ?
                        <Grid item >
                          <Label
                            color="success"
                          >
                            Todas las UMN seleccionadas
                          </Label>
                        </Grid>
                        : agencia?.length === 1 && UMN?.length > 1 ?
                          <TextField
                            sx={{ width: 175, marginBottom: 2, marginLeft: 3, marginTop: 2 }}
                            label="UMN"
                            select
                            SelectProps={{ native: true }}
                            variant="outlined"
                            name="umn"
                            onChange={handleChangeUMN}
                            value={UMN}
                          >
                            <option
                              key={0}
                              value={0}
                            >
                              Todas
                            </option>
                            {
                              role?.includes('tdp_operativa')||role?.includes('cumplimiento') || role?.includes('finanzas') || role?.includes('tesoreria') || role?.includes('conajzar') || role?.includes('conajzar_raspa') || role?.includes('raspa_operativa') || zona?.length > 1 || zona[0] === 0 ?
                                umnS?.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item?.id}
                                  >
                                    {item?.numero} - ({item?.nombre})
                                  </option>
                                )) :
                                umnS?.filter(umn => umn.zona === zona[0])?.map((item) => (
                                  <option
                                    key={item?.id}
                                    value={item?.id}
                                  >
                                    {item?.numero} - ({item?.nombre})
                                  </option>
                                ))
                            }
                          </TextField>
                          :
                          <TextField
                            sx={{ width: 175, marginBottom: 2, marginLeft: 3, marginTop: 2 }}
                            label="UMN"
                            select
                            SelectProps={{ native: true }}
                            variant="outlined"
                            name="umn"
                            onChange={handleChangeUMN}
                            value={UMN}
                          >
                            <option
                              key={0}
                              value={0}
                            >
                              Todas
                            </option>
                            {role?.includes('tdp_operativa') || role?.includes('cumplimiento') || role?.includes('finanzas') || role?.includes('conajzar') || role?.includes('conajzar_raspa') || role?.includes('raspa_operativa') || role?.includes('tesoreria') ?
                              umnS?.map((item) => (
                                <option
                                  key={item?.id}
                                  value={item?.id}
                                >
                                  {item?.numero} - ({item?.nombre})
                                </option>
                              )) :
                              umnS?.filter(umn => umn.zona === zona[0])?.map((item) => (
                                <option
                                  key={item?.id}
                                  value={item?.id}
                                >
                                  {item?.numero} - ({item?.nombre})
                                </option>
                              ))
                            }
                          </TextField>
                    }
                  </Grid>
                </List>
              </Grid>
            }
            {role?.includes('tdp_gerencia') || role?.includes('presidencia') ?
              <List style={{ width: '100%', position: 'relative' }}
                subheader={<ListSubheader sx={{ backgroundColor: 'background.default' }}
                  color='inherit'>Gerencia</ListSubheader>}>
                <Grid container
                  sx={{ marginTop: 4 }}>
                  <FilterGerencia />
                </Grid>
              </List>
              : null}
            {role?.includes('tdp_gerencia') || role?.includes('presidencia') || role?.includes('conajzar_raspa') || role?.includes('raspa_operativa') ?
              null
              :
              <List style={{ width: '100%', position: 'relative' }}>
                <Grid container
                  sx={{ marginTop: 1 }}>
                  <FilterGerencia agencia />
                </Grid>
              </List>
            }
            {role?.includes('finanzas') ||  role?.includes('cumplimiento') || role?.includes('tesoreria') || role?.includes('admin_juego') || role?.includes('conajzar') || role?.includes('conajzar_raspa') || role?.includes('raspa_operativa') ?
              <List style={{ width: '100%', position: 'relative' }}>
                <Grid container
                  sx={{ marginTop: 1 }}>
                  <AgenciesFilter dataAgencia={allAgencies} />
                </Grid>
              </List>
              : null}
            {/* <List style={{width: '100%', position: 'relative'}}> */}
            <Grid container
              sx={{ marginTop: 4 }}>
              <Button
                startIcon={<SearchIcon fontSize="large" />}
                onClick={() => { handleFilter() }}
                sx={{
                  color: 'text.secondary',
                  fontWeight: 600,
                  fontSize: 17,
                  justifyContent: 'center',
                  // pr: '10px',
                  pr: '20px',
                  py: '12px',
                  textAlign: 'left',
                  textTransform: 'none',
                  width: '100%'
                }}
                variant="outlined"
              >
                Buscar
              </Button>
            </Grid>
            {/* </List> */}
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            TDP 2021
          </Typography>
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;

